<template>
    <div class="solution5" id="jumpPoint">
        <div class="banner">
            <div class="banner_title">
                <p class="p1">通&nbsp;&nbsp;信&nbsp;&nbsp;行&nbsp;&nbsp;业&nbsp;&nbsp;配&nbsp;&nbsp;套&nbsp;&nbsp;解&nbsp;&nbsp;决&nbsp;&nbsp;方&nbsp;&nbsp;案</p>
                <p class="p2">超 越 软 件 智 造 未 来</p>
            </div>
        </div>

        <div class="boxes1">
            <p class="pub_title">简介</p>
            <div class="center">
                <div class="left">
                    <img src="./../../assets/Solution5/img1.png" alt="">
                </div>
                <div v-html="content1" class="content">{{content1}}</div>
            </div>
        </div>

        <div class="boxes2">
            <p class="pub_title">解决方案优势</p>
            <div class="center">
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution5/img2.png" alt="">
                    </div>
                    <div class="right">
                        <p>典型应用</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>MES：全过程条码追溯、防错、SOP在线查看</span>
                            <span>测试数据采集、与华为系统对接</span>
                            <span>WMS：全物料采用条码管控，出入库扫码作业</span>
                            <span>SRM：供应商在线打印统一格式的标签、发货单，在线订单查看</span>
                        </div>
                    </div>
                </div>
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution5/img3.png" alt="">
                    </div>
                    <div class="right">
                        <p>项目概况</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>企业规模：600人&emsp;&emsp;&emsp;&emsp;&emsp;实施时间：2019.10</span>
                            <span>实施周期：6个月&emsp;&emsp;&emsp;&emsp;&emsp;实施范围：广州公司、揭阳二厂</span>
                            <span class="font_align">主要工艺：剥线、移相器生产、阵子生产、组装、总装、PM初测、 终测、包装等</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="boxes3">
            <p class="pub_title">应用效果</p>
            <div class="center">
                <img src="./../../assets/Solution5/img4.png" alt="">
                <div v-html="content2" class="content">{{content2}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'Solution5',
    components:{
        
    },
    data(){
        return {
            content1:'广东博纬通信科技有限公司\n集4G&5G移动通信天线研发、生产、销售及工程服务为一体的国家高新技术企业，是深圳市飞荣达科技股份有限公司（股票代码：300602）控股子公司。\n主要客户：华为',
            content2:'原料标签通过SRM在线制作，800家供应商应用\n通过仓库条码，彻底解决了先进先出实物管控，同时ERP单据自动生成、审核，减少人工重复操作\n通过电子看板实现IQC及仓库作业周期大幅度缩短，由原来的平均12小时缩短到6小时\n与华为系统对接，测试数据实时传递到华为系统，减少人工传输\nMES在线防呆，提示品质\n华为审核，系统评分89分',
        }
    },
    
    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    },
}
</script>

<style scoped lang='scss'>
@import './Solution5.scss'
</style>
